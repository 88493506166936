.App {
  width: 100vw;
  height: 100vh;
  background-color: rgb(241, 241, 241);
  overflow-y: scroll;
}
.headerContent {
  position: relative;
  background-color: white;
  margin-top: 1vh;
}
.buttonConnect {
  position: absolute;
  z-index: 1;
  right:1vw;
  width: 120px;
  height:40px;
  border: none;
  background-color: transparent;
  color: #efc75e;
  font-size: 10px;
  cursor: pointer;
}

.Banner {
  position: relative;
  width: 94vw;
  left: 3vw;
  /* width: 100vw; */
  /* top: -1vh; */
  border-radius: 30px;
}

.body {
  position: relative;
  margin-top: 1vh;
  height: 60vh;
}

.bodyInviteArea {
  height: 15vh;
  display: flex;
  flex-direction: row;
  background-color: #a01323;
  color: #efc75e;
}
.bodyInviteAreaLeft {
  flex:7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#bodyContentTitle{
  font-weight: 1000;  
  font-size: 40px;
}
#bodyContentContent{
  font-weight: 300;  
  font-size: 20px;
}
.bodyInviteAreaRight {
  flex:3;
}
#moneyPic {
  max-height: 15vh;
}

.bodyNavbarArea{
  height: 5vh;
  display: flex;
  flex-direction: row;
  background-color: white;
  /* border-bottom: 1px solid #efc75e; */
  /* background-color: #a01323; */
  margin-top: 1vh;
}

.whiteLine {
  height: 5vh;
  display: flex;
  flex-direction: row;
  background-color: white;
}

.bodyNav {
  display: flex;
  flex-direction: row;
  width: 100vw;
}

.bodyNavElement {
  display: flex;
  flex:1;
  align-items: center;
  justify-content: center;
}


/* Modal */
.overlay {
  /* background-color: rgba(0, 0, 0, 0.5); */
  position: fixed;
  width: 100%;
  height: 100%;
}
.modalRight {
  width: 100%;
}

.modalGameContainer {
  /* background-color: white; */
  max-width: 300px;
  height: 240px;
  width: 100%;
  position: fixed;
  z-index: 45;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: 100% 100%;
  display: flex;
  background-repeat: no-repeat;
  /* box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75); */
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  background-color: #a01323;
  border: 1px solid #efc75e;
  color: #efc75e;
}

.ModalContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
}

.ref {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.IDOButtonDiv {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: -60px;
  margin-top: 2vh;
  margin-bottom: 2vh;
}
#buttonIDO {
  color: #efc75e;
  border: 2px solid #efc75e;
  background-color: transparent;
  border-radius: 20px;
  width: 120px;
  height: 40px;
}

.closeBtn {
  color: #efc75e;
  position: fixed;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.whiteLine {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a01323;
  font-size: large;
  font-weight: 1000;
}

.IDOTotalSection,
.statusPage,
.claimPage {
  display: flex;
  flex-direction: column;
  background-color: #a01323;
  color: #efc75e;
}

.claimPage {
  height: 40vh;
  align-items: center;
  justify-content: center;
}

.buttonClaim {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
#btnClaim {
  background-color: transparent;
  color: #efc75e;
  border: 3px solid #efc75e;
  border-radius: 20px;
  width: 120px;
  height: 40px;
  font-size: large;
  font-weight: 1000;
}
#inviteLink {
  background-color: transparent;
  color: #efc75e;
  border: 3px solid #efc75e;
  border-radius: 20px;
  width: 120px;
  height: 40px;
  font-size: large;
  font-weight: 1000;
}
.copyLink {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.statusRow {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
}

.IDOTotalSectionLeft,
.statusLeft {
  flex: 5;
  font-family: "Arial","Microsoft YaHei","黑体","宋体",sans-serif;
  margin-left: 40px;
  font-weight: 1000;
}
.IDOTotalSectionRight,
.statusRight {
  flex : 3;
  font-family: "Arial","Microsoft YaHei","黑体","宋体",sans-serif;
  margin-right: 40px;
  font-weight: 800;
}

.footer {
  position: relative;
  top : 80vh;
  height: 20vh;
  border: 3px solid black;
}

@media screen and (max-width : 600px){
  #bodyContentTitle{
    font-weight: 1000;  
    font-size: 15px;
  }
  #bodyContentContent{
    font-weight: 300;  
    font-size: 8px;
  }
  .bodyInviteArea {
    height: 10vh;
  }
  #moneyPic {
    max-height: 10vh;
  }
}